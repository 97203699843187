import ChatBot from "react-chatbotify";
import keycloak from "../../services/keycloak";

export const Chat = () => {
    let hasError = false;
    let chatbotSocket: any;

    const connectChatBackend = () => {
        chatbotSocket = new WebSocket(
            // TODO Configuration Item
            // `ws://localhost:8084/stream?token=${keycloak.token}`
            `wss://bot.pan.mayflower.tech/stream?token=${keycloak.token}`
        );

        return "Hallo, ich bin der panTerra ChatBot. Du kannst mich alles über deine Projekte und panTerra fragen!";
    }
    const websocketMessage = async (params: any) => {
        try {

            const replyPromise = await new Promise((resolve, reject) => {
                chatbotSocket.send(params.userInput);

                chatbotSocket.onmessage = (event: any) => {
                    console.log(event.data);
                    resolve(event.data);
                };
              });

              await params.injectMessage(replyPromise);
        } catch (error) {
            await params.injectMessage("Etwas ist schief gelaufen. Bitte versuche es erneut.");
            hasError = true;
        }
    }
    const flow = {
        start: {
            message: connectChatBackend,
            path: "loop"
        },
        loop: {
            message: async (params: any) => {
                await websocketMessage(params);
            },
            path: () => {
                if (hasError) {
                    return "start"
                }
                return "loop"
            }
        }
    }
    return (
        <ChatBot flow={flow} />
        // <ChatBot settings={{general: {embedded: true}, chatHistory: {storageKey: "example_real_time_stream"}, botBubble: {simStream: true}}} flow={flow}/>
    );
};
